import "./styles.scss"

import React from "react"
import { Link } from "gatsby"
// import Slider from "react-slick"

import { isUserLoggedIn } from "utils/functions"

const Hero = ({ data }) => {
  const auth = isUserLoggedIn()

  // const slider = {
  //   dots: false,
  //   arrows: false,
  //   infinite: true,
  //   speed: 750,
  //   autoplay: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   fade: true,
  //   pauseOnHover: false,
  //   adaptiveHeight: true,
  // }

  // const slogan = [
  //   "Wkomponowana w naturę",
  //   "Rezydencjalny charakter",
  //   "Prywatne ogródki",
  //   "Wysokie mieszkania",
  //   "Panoramiczne okna",
  // ]

  return (
    <section className="investment-hero">
      <div className="container-fluid">
        <div className="investment-hero__wrapper">
          <h2>{data.name} </h2>
          {/* <div className="investment-hero__slider">
            <Slider {...slider}>
              {slogan.map((item, index) => (
                <span key={index}>{item}</span>
              ))}
            </Slider>
          </div> */}
          {auth ? (
            <Link to="/projekt-23/">
              powrót <span></span>
            </Link>
          ) : (
            <a
              href="https://trustinvestment.pl/mieszkania/kielce/krakowska-rogatka/"
              target="_blank"
              rel="noreferrer"
            >
              powrót <span></span>
            </a>
          )}
        </div>
      </div>
    </section>
  )
}

export default Hero
