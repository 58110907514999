import "./styles.scss"

import React from "react"
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"
import { withPrefix } from "gatsby"
import mapStyleStacjaKopernik from "utils/mapStyleStacjaKopernik"

const Location = ({ zoom = 14 }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAw_QqzRWAoMaaZZQLLfsZVgeqAnhLeiWc",
  })

  const containerStyle = {
    width: "100%",
    height: "600px",
  }

  const center = {
    lat: 50.8644275,
    lng: 20.6290916,
  }

  return (
    <section className="investment-location">
      <div className="container-fluid">
        <div className="investment-location__top">
          <h3>Lokalizacja</h3>
          <address>
            <strong>Kielce</strong>
            ul. Miodowicza
          </address>
        </div>
      </div>
      <div className="google-map">
        {isLoaded && (
          <GoogleMap
            center={center}
            zoom={zoom}
            options={{ styles: mapStyleStacjaKopernik }}
            mapContainerStyle={containerStyle}
          >
            <Marker icon={withPrefix("/pointer.svg")} position={center} />
          </GoogleMap>
        )}
      </div>
    </section>
  )
}

export default Location
