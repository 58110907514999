import "./styles.scss"

import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"

import Input from "components/Input"
import Checkbox from "components/Checkbox"
import Button from "components/Button"

const InvestPopupForm = () => {
  const formID = "192"
  const formIDdistribution = "197"
  const [send, setSend] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [typePhone, setTypePhone] = useState(null)
  const [typeEmail, setTypeEmail] = useState(null)
  const [message, setMessage] = useState(
    `Proszę o kontakt w sprawie inwestycji TRUST INVESTMENT PROJEKT 23.`
  )
  const [legalFirst, setLegalFirst] = useState(false)

  const resetForm = () => {
    setSend(false)
    setName("")
    setEmail("")
    setPhone("")
    setMessage("")
    setLegalFirst(false)
  }

  const formSubmit = e => {
    e.preventDefault()
    setSend(true)

    let formData = new FormData()

    formData.set("firstName", name)
    formData.set("email", email)
    formData.set("phone", phone)
    formData.set("message", message)
    formData.set("typePhone", typePhone)
    formData.set("typeEmail", typeEmail)
    formData.set("date", new Date().toLocaleString() + "")
    formData.set("legalFirst", legalFirst)

    axios({
      method: "post",
      url: `https://trust-project-23.zenx.pl/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback/`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        console.log("Submit success")

        axios({
          method: "post",
          url: `https://trust-project-23.zenx.pl/wp-json/contact-form-7/v1/contact-forms/${formIDdistribution}/feedback/`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(() => {
            console.log("Submit success dist")
            resetForm()
            navigate("/kontakt-potwierdzenie/")
          })
          .catch(err => {
            console.log(err)
          })
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <form onSubmit={formSubmit}>
      <Input
        placeholder="imię i nazwisko*"
        type="text"
        id="name"
        name="name"
        value={name}
        onChange={e => setName(e.target.value)}
        required
      />

      <div className="row">
        <div className="col-sm-6">
          <Input
            placeholder="telefon*"
            type="phone"
            id="phone"
            name="phone"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            required
          />
        </div>
        <div className="col-sm-6">
          <Input
            placeholder="e-mail*"
            type="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            id="email"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
        </div>
      </div>
      <Input
        placeholder="treść wiadomości"
        type="textarea"
        id="message"
        name="message"
        value={message}
        onChange={e => setMessage(e.target.value)}
        rows="9"
      />

      <div className="contact-form__type">
        <strong>Preferowana format kontaktu</strong>
        <div className="row">
          <div className="col-sm-6">
            <Checkbox
              name="type"
              checked={typePhone}
              onChange={() => setTypePhone(!typePhone)}
            >
              Telefon
            </Checkbox>
          </div>
          <div className="col-sm-6">
            <Checkbox
              name="type"
              checked={typeEmail}
              onChange={() => setTypeEmail(!typeEmail)}
            >
              Wiadomość email
            </Checkbox>
          </div>
        </div>
      </div>

      <div className="contact-form__action">
        <Checkbox
          name="legalFirst"
          checked={legalFirst}
          onChange={() => setLegalFirst(!legalFirst)}
          required
        >
          Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych przez
          Trust Investment Projekt 23 sp. z o.o., ul. Robotnicza 1, 25-662
          Kielce NIP: 9592059901, REGON: 524635572, KRS: 0001022068
          (Administrator danych), w celu marketingu bezpośredniego dotyczącego
          własnych produktów i usług. Obowiązek informacyjny zgodnie z art. 13
          ogólnego rozporządzenia o ochronie danych osobowych (RODO) z dnia 27
          kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016): informacje o
          przetwarzaniu przez nas danych, jak również o prawach osób, których
          dane dotyczą znajdują się w naszej polityce prywatności polityce
          prywatności.
        </Checkbox>
        <Button className="button" type="submit" disabled={!legalFirst}>
          {send === true ? "Wysyłanie.." : "Wyślij"}
        </Button>
      </div>
    </form>
  )
}

export default InvestPopupForm
