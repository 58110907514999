/* eslint-disable react/jsx-no-target-blank */

import "./styles.scss"

import React from "react"

import IcoDownload from "assets/icons/download.svg"
import { withPrefix } from "gatsby"

const Docs = () => {
  return (
    <section className="investment-docs">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8">
            <h3>Dokumenty do pobrania</h3>
            <a
              href={withPrefix("/TIP23-Krakowska-Rogatka-teaser.pdf")}
              target="_blank"
            >
              <img src={IcoDownload} alt="" /> Teaser sprzedażowy
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Docs
