/* eslint-disable no-unused-vars */

import React, { useContext } from "react"
import { navigate } from "gatsby"
import { useQuery, useMutation } from "@apollo/client"
import { v4 } from "uuid"
import { toast } from "react-toastify"

import Button from "components/Button"

import { AppContext } from "context/app_context"
import { getFormattedCart, isUserLoggedIn } from "utils/functions"

import GET_CART from "queries/get-cart"
import ADD_TO_CART from "mutations/add-to-cart"

const AddToCart = ({ product, quantity, productVariation }) => {
  const auth = isUserLoggedIn()
  const [cart, setCart] = useContext(AppContext)

  const isBrowser = typeof window !== "undefined"
  const queryParameters = new URLSearchParams(
    isBrowser && window.location.search
  )
  const partnerParam = queryParameters.get("partner")

  const productQtyInput = {
    clientMutationId: v4(),
    // productId: product?.databaseId,
    productId: productVariation,
    quantity: quantity ? parseInt(quantity) : 1,
  }

  // Get Cart Data
  const { data, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      const updatedCart = getFormattedCart(data)
      localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart))
      setCart(updatedCart)
    },
    onError: error => {
      console.warn(error)
    },
  })

  const [addToCart, { loading: addToCartLoading, error: addToCartError }] =
    useMutation(ADD_TO_CART, {
      variables: {
        input: productQtyInput,
      },
      onCompleted: data => {
        if (addToCartError) {
          console.warn(addToCartError.graphQLErrors[0].message)
        }
        refetch()

        auth
          ? navigate(
              `/zamowienie/${partnerParam ? `?partner=${partnerParam}` : ""}`
            )
          : navigate(
              `/logowanie/?order=true${
                partnerParam ? `&partner=${partnerParam}` : ""
              }`
            )
        !auth &&
          toast.warning(
            "Aby przejść do ścieżki transakcji prosimy o zalogowanie się."
          )
      },
      onError: error => {
        if (error) {
          error.message ===
          '<a href="https://trustinvestmentprojekt23.pl/" class="button wc-forward">Zobacz koszyk</a> Nie możesz dodać takiej ilości do koszyka &mdash; w magazynie posiadamy 1 a w koszyku masz już 1.'
            ? toast.warning("Brak dostępnej ilości sztuk towaru w magazynie.")
            : toast.error("Wystąpił błąd")
          console.warn(error)
        }
      },
    })

  return (
    product?.stockStatus === "IN_STOCK" && (
      <Button
        className="button--blue"
        type="submit"
        onClick={addToCart}
        disabled={!productVariation}
      >
        {addToCartLoading ? "proszę czekać..." : "Zacznij inwestować"}
      </Button>
    )
  )
}

export default AddToCart
